import React from 'react';

function Footer() {
    return (
        <footer>
            Created by <a href="https://www.linkedin.com/in/ldtcooper/" target="_blank" rel="noreferrer">Logan Cooper</a>
        </footer>
    );
}

export default Footer;